import { getRequest, postRequest, deleteRequest, putRequest } from '../httpclient/httpClient';
import * as authService from '../auth/authService';
import { prepareDateFormatParams } from '../helper/dateTimeHelper';

const DASHBOARD_PATH = 'dashboard';
const DASHBOARD_TABLE_PATH = 'dashboard/table';
const DASHBOARD_TABLE_EXPORT_PATH = 'dashboard/export_table';
const DASHBOARD_CHART_PATH = 'dashboard_chart';
const DASHBOARD_GRID_PATH = 'dashboard_grid';
const DASHBOAR_VIEWABLE_PATH = 'dashboard/viewable';
const DASHBOARD_EDITABLE_PATH = 'dashboard/editable';
const FAVORITE_DASHBOARD_IDS = 'dashboard/favorite_dashboard_ids';
const WEB_DASHBOARD_ID = 'dashboard/web_dashboard_id';
const MOBILE_DASHBOARD_ID = 'dashboard/mobile_dashboard_id';
const DASHBOARD_MENU_ITEMS = 'dashboard/dashboard_menu_items';
const MANAGE_DASHBOARD_FAVORITE = 'dashboard/manage_dashboard_favorite';
const getDashboardTablePath = (dashboardId) => `dashboard/${dashboardId}/table`;
const getDashboardCreateTablePath = (dashboardId) => `dashboard/${dashboardId}/table`;

export const authorizationType = {
  user: { id: 1, key: 'user' },
  userTag: { id: 2, key: 'userTag' },
  team: { id: 3, key: 'team' },
  role: { id: 4, key: 'role' }
};

export function getAuthorizationType(id) {
  for (const key in authorizationType) {
    if (authorizationType[key].id === id) {
      return authorizationType[key];
    }
  }

  return null;
}

export async function canViewDashboard(id) {
  return (await getRequest(`${DASHBOAR_VIEWABLE_PATH}/${id}`)).data;
}

export async function canEditDashboard(id) {
  return (await getRequest(`${DASHBOARD_EDITABLE_PATH}/${id}`)).data;
}

function hasAccessRight(userInfo) {
  return userInfo && userInfo.accessRightIds && userInfo.accessRightIds.includes(authService.accessRights.CustomDashboards);
}

export function canEdit(userInfo) {
  return hasAccessRight(userInfo) && authService.hasModuleEditRight(userInfo, authService.modules.CustomDashboards);
}

export function canView(userInfo) {
  return hasAccessRight(userInfo) && authService.hasModuleViewRight(userInfo, authService.modules.CustomDashboards);
}

export async function dashboards(searchParams) {
  const config = {
    params: searchParams
  };
  const resources = await getRequest(DASHBOARD_PATH, config, true);
  return resources.data;
}

export async function dashboardTable(tableId, searchParams) {
  const params = {
    ...searchParams
  };

  if (params.dateInterval && params.dateInterval !== '') {
    const dateParams = prepareDateFormatParams(params);
    params.startDate = dateParams.startDate;
    params.endDate = dateParams.endDate;
  }

  const config = {
    params
  };

  const resources = await getRequest(`${DASHBOARD_TABLE_PATH}/${tableId}`, config, true);
  return resources.data;
}
export async function dashboardTableExport(searchParams, tableId, totalCount) {
  const params = {
    dashboardTableParams: {
      excludeMaxLimit: true,
      ...searchParams
    },
    dashboardTableId: tableId,
    totalCount
  };

  if (params.dateInterval && params.dateInterval !== '') {
    const dateParams = prepareDateFormatParams(params);
    params.startDate = dateParams.startDate;
    params.endDate = dateParams.endDate;
  }

  const resources = await postRequest(`${DASHBOARD_TABLE_EXPORT_PATH}`, params);
  return resources.data;
}

export async function dashboardTables(dashboardId) {
  return (await getRequest(getDashboardTablePath(dashboardId))).data;
}

export async function save(dashboard) {
  if (dashboard.id > 0) {
    const resources = await putRequest(`${DASHBOARD_PATH}/${dashboard.id}`, dashboard);
    return resources.data;
  } else {
    const resources = await postRequest(DASHBOARD_PATH, dashboard);
    return resources.data;
  }
}

export async function deleteDashboard(dashboardId) {
  await deleteRequest(`${DASHBOARD_PATH}/${dashboardId}`);
}

export async function dashboard(dashboardId) {
  return (await getRequest(`${DASHBOARD_PATH}/${dashboardId}`)).data;
}

export async function dashboardChart(dashboardId) {
  return (await getRequest(`${DASHBOARD_CHART_PATH}/${dashboardId}`)).data;
}

export async function dashboardGrid(dashboardId) {
  return (await getRequest(`${DASHBOARD_GRID_PATH}/${dashboardId}`)).data;
}

export async function saveDashboardGrid(dashboardGrid) {
  return (await putRequest(`${DASHBOARD_GRID_PATH}/${dashboardGrid.dashboardId}`, dashboardGrid)).data;
}

export async function createTable(dashboardId) {
  return (await postRequest(getDashboardCreateTablePath(dashboardId))).data;
}

export async function deleteTable(tableId) {
  return (await deleteRequest(`${DASHBOARD_TABLE_PATH}/${tableId}`)).data;
}

export async function saveTable(dashboardTable) {
  return (await putRequest(`${DASHBOARD_TABLE_PATH}/${dashboardTable.id}`, dashboardTable)).data;
}

export async function favoriteDashboardIds() {
  return (await getRequest(FAVORITE_DASHBOARD_IDS)).data;
}

export async function webDashboardId() {
  return (await getRequest(WEB_DASHBOARD_ID)).data;
}

export async function mobileDashboardId() {
  return (await getRequest(MOBILE_DASHBOARD_ID)).data;
}

export async function dashboardMenuItems() {
  return (await getRequest(DASHBOARD_MENU_ITEMS)).data;
}

export async function manageDashboardFavorite(dashboardId, isFavoriteAdded) {
  const body = {
    dashboardId,
    isFavoriteAdded
  };

  return (await postRequest(MANAGE_DASHBOARD_FAVORITE, body)).data;
}
