import React, { useState } from 'react';
import { Menu, MenuItem, ListItemText, Box, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';

export default function FavoriteDashboardSearch(props) {
  const { dashboards, anchorEl, setAnchorEl } = props;
  const [searchQuery, setSearchQuery] = useState('');

  function handleClose() {
    setAnchorEl(null);
  }

  const filteredDashboards = dashboards.filter((dashboard) => dashboard.name.toLowerCase().includes(searchQuery.toLowerCase()));

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      className="favdashboard-search-menu"
      slotProps={{
        paper: {
          sx: {
            width: '400px',
            maxHeight: '300px',
            overflowY: 'hidden',
            marginLeft: '5px'
          }
        }
      }}
    >
      <Box sx={{ padding: '10px' }}>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          sx={{
            input: { padding: '5px' },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#455a64cc'
              },
              '&:hover fieldset': {
                borderColor: '#455a64cc'
              },
              '&.Mui-focused fieldset': {
                borderColor: '#455a64cc'
              }
            }
          }}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon color="disabled" style={{ width: '20px' }} />
              </InputAdornment>
            )
          }}
        />
      </Box>

      <Box sx={{ maxHeight: '230px', overflowY: 'scroll' }}>
        {filteredDashboards.map((dashboard, index) => (
          <MenuItem
            key={index}
            onClick={() => (window.location.href = `/dashboard/${dashboard.id}`)}
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              borderBottom: '1px solid #ccc',
              '&:last-child': {
                borderBottom: 'none'
              }
            }}
          >
            <ListItemText
              sx={{
                whiteSpace: 'normal',
                wordBreak: 'break-word',
                display: 'block',
                color: '#455a64cc',
                padding: '3px'
              }}
              onMouseOver={(e) => (e.target.style.color = '#009587')}
              onMouseOut={(e) => (e.target.style.color = '#455a64cc')}
              primary={dashboard.name}
            />
          </MenuItem>
        ))}
      </Box>
    </Menu>
  );
}

FavoriteDashboardSearch.propTypes = {
  dashboards: PropTypes.array.isRequired,
  anchorEl: PropTypes.object,
  setAnchorEl: PropTypes.func.isRequired
};
